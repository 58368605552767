import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import AudioInput from './components/speechmatics/audioInput';
import SpeechRecognitionConnections from './speechRecognitionConnections';
import logo from './logo.png';
import './App.css';

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: '100vh',
		backgroundColor: '#f5f5f5',
	},
	flexContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		flexDirection: 'column',
		width: '100%',
		backgroundColor: '#F5F5F5',
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
		height: 70,
	},
	rightHeader: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	logoRoot: {
		height: 50,
		paddingLeft: theme.spacing(2),
	},
	logo: {
		height: 50,
	},
}));

function Logo() {
	const classes = useStyles();

	return (
		<div className={classes.logoRoot}>
			<img src={logo} className={classes.logo} alt="logo" />
		</div>
	);
}

function Main() {
	const classes = useStyles();
	const [sampleRate, setSampleRate] = useState(null);
	const [inputSrc, setInputSrc] = useState('record');
	const [language, setLanguage] = useState('');
	const [apiKey, setApiKey] = useState(null);
	const [isStreaming, setStreaming] = useState(null);

	const [inputData, setInputData] = useState(null);
	const [isFile, setFile] = useState(false);
	const canStartSocket = language !== 'cleared' || language !== '';
	return (
		<div className={classes.root}>
			{/* Header */}
			<Paper className={classes.header}>
				<Logo />
			</Paper>
			{/* Body */}
			<div className={classes.flexContainer}>
				<AudioInput
					setSampleRate={setSampleRate}
					setLanguage={setLanguage}
					setApiKey={setApiKey}
					language={language}
					setInputData={setInputData}
					setFile={setFile}
					apiKey={apiKey}
					isStreaming={isStreaming}
					setStreaming={setStreaming}
					setInputSrc={setInputSrc}
				/>
				{language &&
					language !== 'cleared' &&
					language !== '' &&
					apiKey &&
					(sampleRate || inputSrc === 'file') && (
						<SpeechRecognitionConnections
							sampleRate={sampleRate}
							apiKey={apiKey}
							canStartSocket={canStartSocket}
							inputSrc={inputSrc}
							isStreaming={isStreaming}
							setLanguage={setLanguage}
							inputData={inputData}
							languageProp={language}
							isFile={isFile}
						/>
					)}
			</div>
		</div>
	);
}

export default Main;
