import React, { useEffect, useState } from 'react';
import TextArea from './components/speechmatics/textArea';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';

import { makeStyles } from '@material-ui/core/styles';
import CustomizedSnackbars from './components/speechmatics/alert.js';
let socket = null;
let transcription = [];

const useStyles = makeStyles((theme) => ({
	transitionBtnColour: {
		backgroundColor: 'red',
	},
	downloadBtn: {
		height: '25px',
		fontSize: '14px',
		border: 0,
		borderRadius: '35px',
		outline: 'none',
		transition: 'background-color 0.5s',
	},
	btnLabelContainer: {
		display: 'flex',
		alignItems: 'center',
		width: 'fit-content',
	},
}));
function SpeechRecognitionConnections(props) {
	const {
		inputData,
		languageProp,
		isFile,
		sampleRate,
		canStartSocket,
		setLanguage,
		isStreaming,
		apiKey,
	} = props;
	const [textValue, setTextValue] = useState('');
	const [canDownload, setDownload] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [hasWarning, setHasWarning] = useState(false);
	const [hasInfo, setHasInfo] = useState(false);
	const [message, setMessage] = useState(null);
	const classes = useStyles();

	useEffect(() => {
		if (inputData && socket && socket.readyState === 1) {
			// const adjustedInputData = toInt16Array(inputData);
			if (socket.bufferedAmount === 0) {
				if (isFile) {
					let chunkSize = 1024 * 1024;
					let chunks = Math.ceil(inputData.size / chunkSize, chunkSize);
					let chunk = 0;

					while (chunk <= chunks) {
						let offset = chunk * chunkSize;
						if (!isStreaming) {
							break;
						}
						socket.send(inputData.slice(offset, offset + chunkSize));

						chunk++;
					}
				} else {
					socket.send(new Float32Array(inputData));
				}
			}
		} else if (
			!inputData &&
			!isStreaming &&
			socket &&
			socket.readyState === 1
		) {
			console.log(inputData);
			setLanguage('cleared');
			socket.send(JSON.stringify({ messageType: 'TRANSCRIPTION_FINISHED' }));
		}
	}, [inputData, isFile, isStreaming, setLanguage]);
	let saveAs = (function () {
		var a = document.createElement('a');
		document.body.appendChild(a);
		a.style = 'display: none';
		return function (fileName) {
			const blob = new Blob(transcription, {
				type: 'text/plain;charset=utf-8',
			});
			var url = window.URL.createObjectURL(blob);
			a.href = url;
			a.download = fileName;
			a.click();
			window.URL.revokeObjectURL(url);
		};
	})();
	useEffect(() => {
		const openSocket = (serverName) => {
			if (socket) {
				socket.close();
			} else if (canStartSocket) {
				console.info(`OPEN A NEW SOCKET FOR ${languageProp}`);
				socket = new WebSocket(serverName);
				socket.binaryType = 'arraybuffer';
				socket.onopen = () => onOpen(languageProp);
				socket.onclose = (event) => {
					console.log(event);
				};
				socket.onmessage = (event) => onMessage(event);
				socket.onerror = (evt) => onError(evt);
			}
		};
		function onError(evt) {
			console.error(evt);
			setHasError('error');
		}
		function onMessage(evt) {
			let objMsg = JSON.parse(evt.data);
			switch (objMsg.type) {
				case 'PartialResult':
					break;

				case 'FinalResult':
					let segResults = objMsg.message.transcript;
					if (
						objMsg.message.segments[0].start === 0 &&
						transcription.length > 0
					) {
						setTextValue(
							(prevTextValue) =>
								prevTextValue + '\n' + objMsg.message.transcript
						);
					} else {
						setTextValue(
							(prevTextValue) => prevTextValue + ' ' + objMsg.message.transcript
						);
					}
					transcription.push(segResults);
					if (!canDownload) {
						setDownload(true);
					}
					// let msgEl = document.getElementById('btn-download-transcript');
					// setTimeout(function () {
					// 	msgEl.style.backgroundColor = 'transparent';
					// }, 500);
					break;
				case 'Info':
					setHasInfo('info')
					setMessage(objMsg.message);
					console.info(objMsg);
					break;

				case 'Warning':
					setHasWarning('warning')
					setMessage(objMsg.message);
					console.warn(objMsg);
					break;

				case 'Error':
					console.error(objMsg);
					setHasError('error');
					setMessage(objMsg.message);
					setLanguage('cleared');
					socket.close();
					break;
				default:
					console.info('UNKNOWN MESSAGE: ' + objMsg.message);
			}
			return false;
		}
		function onOpen(lang) {
			let msg = JSON.stringify({
				messageType: 'INIT',
				language: lang,
				apiKey: apiKey,
				audioConfig: {
					sample_rate: sampleRate,
					encoding: 'f32le',
				},
				outputConfig: {
					format: 'transcription',
					partials: false,
				},
			});
			socket.alive = true;
			socket.send(msg);
		}
		function closeSocket() {
			console.info(`CLOSE THE SOCKET FOR ${languageProp}`);
			if (socket) {
				socket.close();
				socket = null;
			}
		}
		if (languageProp === '' || languageProp === 'cleared') {
			closeSocket();
		}

		openSocket(process.env.REACT_APP_URL || 'wss://api-staging-k8s.amberscript.com/real-time/');
		// openSocket('ws://127.0.0.1:5000/');
		// eslint-disable-next-line
	}, [languageProp, isFile, sampleRate, setLanguage, canStartSocket, apiKey]);

	return (
		<React.Fragment>
			<TextArea value={textValue === '' ? undefined : textValue} />
			{canDownload && (
				<div className={classes.btnLabelContainer}>
					<Button
						onClick={() => saveAs('transcription.txt')}
						variant="contained"
						id="btn-download-transcript"
						className={classes.downloadBtn}
						component="label"
						color="primary"
						startIcon={<SaveIcon />}
					>
						Download transcription
					</Button>
				</div>
			)}
			<CustomizedSnackbars
				hasError={hasError}
				hasWarning={hasWarning}
				hasInfo={hasInfo}
				message={message}
			/>
		</React.Fragment>
	);
}

export default SpeechRecognitionConnections;
