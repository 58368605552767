const languageSet = [
    {name: 'English', value: 'en'},
    // {name: 'English (United Kingdom)', value: 'en'},
    {name: 'Dutch', value: 'nl'},
    {name: 'German', value: 'de'},
    {name: 'French', value: 'fr'},
    // {name: 'Spanish', value: 'es'},
    // {name: 'Italian', value: 'it-IT'},
];
 
export {
    languageSet,
}
