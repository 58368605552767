import React from 'react';
import TextField from '@material-ui/core/TextField';
import {
	makeStyles,
	ThemeProvider,
	createMuiTheme,
} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
const theme = createMuiTheme();

const useStyles = makeStyles((theme) => ({
	textContainer: {
		margin: theme.spacing(1),
		overflow: 'auto',
		paddingTop: '5px',
		width: '50%',
		height: '50%',
	},
	input: {
		border: 'none'
	},
	textArea: {
		backgroundColor: 'white',
	},
}));

export default function TextArea({ value }) {
	let classes = useStyles();
	return (
		<ThemeProvider theme={theme}>
			<Container
				className={classes.textContainer}
				maxWidth={false}
				fixed
				disableGutters>
				<TextField
					value={value}
					className={classes.textArea}
					id='standard-multiline-static'
					label='Output'
					multiline
					inputProps={{ 'aria-label': 'naked' }}
					fullWidth
					disabled={true}
					rows={10}
					defaultValue='Transcription will appear here...'
					variant='outlined'
				/>
			</Container>
		</ThemeProvider>
	);
}
