import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	FormControl,
	InputLabel,
	Select,
	TextField,
	MenuItem,
} from '@material-ui/core';
import { languageSet } from '../../languageSet';
const useStyles = makeStyles((theme) => ({
	formControl: {
		flex: '1 1 auto',
		paddingRight: theme.spacing(2),
		marginLeft: theme.spacing(4),
		minWidth: 200,
	},
	divForm: {
		display: 'flex',
		flexDirection: 'column',
	},
}));

export default function LanguageSelect(props) {
	const { language, setLanguage, setApiKey } = props;
	const classes = useStyles();
	const [apivalue, setValue] = useState('');

	function changeLanguage(newLang) {
		setLanguage(newLang);
	}

	function changeAPIKey(newAPI) {
		setValue(newAPI);
		setApiKey(newAPI);
	}

	return (
		<div className={classes.divForm}>
			<FormControl className={classes.formControl}>
				<InputLabel id="demo-simple-select-label">Language</InputLabel>
				<Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={language === 'cleared' ? '' : language}
					onChange={(event) => changeLanguage(event.target.value)}
				>
					{languageSet.map((lang) => (
						<MenuItem value={lang.value} key={lang.name}>
							{lang.name}
						</MenuItem>
					))}
				</Select>
			</FormControl>

			<FormControl className={classes.formControl}>
				<TextField
					id="demo-simple-text"
					label="API Key"
					value={apivalue}
					style={{ marginTop: '14px' }}
					onChange={(event) => changeAPIKey(event.target.value)}
				></TextField>
			</FormControl>
		</div>
	);
}
