import React from 'react';
import {
	makeStyles,
	ThemeProvider,
	createMuiTheme,
} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LanugageSelect from './languageSelector';
import Container from '@material-ui/core/Container';
const theme = createMuiTheme();
function a11yProps(index) {
	return {
		id: `wrapped-tab-${index}`,
		'aria-controls': `wrapped-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		height: '50%',
		width: 'auto',
		backgroundColor: 'white',
		boxShadow: theme.shadows[3],
	},
	appBarColour: {
		backgroundColor: '#3abaca',
	},
}));

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`wrapped-tabpanel-${index}`}
			aria-labelledby={`wrapped-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography component={'span'} variant={'body2'}>
						{children}
					</Typography>
				</Box>
			)}
		</div>
	);
}

export default function TabsWrappedLabel({
	button,
	uploadFileBtn,
	language,
	setLanguage,
	setApiKey,
	apiKey,
	setInputSrc,
	setFile,
}) {
	const classes = useStyles();
	const [value, setValue] = React.useState('record');

	const handleChange = (event, newValue) => {
		setValue(newValue);
		setInputSrc(newValue);
		setFile(newValue === 'file');
		setLanguage('');
	};
	return (
		<ThemeProvider theme={theme}>
			<Container className={classes.root} maxWidth={false} fixed disableGutters>
				<AppBar className={classes.appBarColour} position="static">
					<Tabs
						value={value}
						onChange={handleChange}
						aria-label="wrapped label tabs example"
					>
						<Tab
							value="record"
							label="Microphone"
							wrapped
							{...a11yProps('record')}
						/>
						<Tab value="file" label="Upload file" {...a11yProps('file')} />
					</Tabs>
				</AppBar>
				<TabPanel value={value} index="record">
					<LanugageSelect
						language={language}
						setLanguage={setLanguage}
						setApiKey={setApiKey}
					/>
					{language !== '' && language !== 'cleared' && apiKey ? button : null}
				</TabPanel>
				<TabPanel value={value} index="file">
					<LanugageSelect
						language={language}
						setLanguage={setLanguage}
						etApiKey={setApiKey}
					/>
					{language !== '' && language !== 'cleared' && apiKey
						? uploadFileBtn
						: null}
				</TabPanel>
			</Container>
		</ThemeProvider>
	);
}
