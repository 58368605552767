import React, { useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		'& > * + *': {
			marginTop: theme.spacing(2),
		},
	},
}));
export default function CustomizedSnackbars(props) {
	const classes = useStyles();
	const { hasError, hasInfo, hasWarning, message } = props;
	const [open, setOpen] = React.useState(false);
	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};
	useEffect(() => {
		if (hasError || hasInfo || hasWarning) {
			setOpen(true);
		}
	}, [hasError, hasInfo, hasWarning]);
	return (
		<div className={classes.root}>
			<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} severity={hasError || hasWarning || hasInfo}>
					{message}
				</Alert>
			</Snackbar>
			{/* <Alert severity="warning">This is a warning message!</Alert>
        <Alert severity="info">This is an information message!</Alert>
        <Alert severity="success">This is a success message!</Alert> */}
		</div>
	);
}
