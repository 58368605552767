import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import KeyboardVoiceIcon from '@material-ui/icons/KeyboardVoice';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import AudioSelectTabs from './audioTabSelection';
const BUFFER_SIZE = 2048;
const useStyles = makeStyles((theme) => ({
	'@keyframes pulse': {
		'0%': {
			boxShadow: '0px 0px 5px 0px rgba(173,0,0,.3)',
		},
		'65%': {
			boxShadow: '0px 0px 5px 13px rgba(173,0,0,.3)',
		},
		'90%': {
			boxShadow: '0px 0px 5px 13px rgba(173,0,0,0)',
		},
	},
	buttonRec: {
		height: '25px',
		fontSize: '14px',
		backgroundColor: 'red',
		border: 0,
		borderRadius: '35px',
		margin: '18px 18px 18px 0',
		outline: 'none',
	},

	notRec: {
		backgroundColor: 'darkred',
	},

	rec: {
		animationName: '$pulse',
		animationDuration: '1.5s',
		animationIterationCount: 'infinite',
		animationTimingFunction: 'linear',
	},
	uploadBtn: {
		height: '25px',
		fontSize: '14px',
		backgroundColor: 'darkred',
		border: 0,
		borderRadius: '35px',
		margin: '18px 18px 18px 16px',
		outline: 'none',
	},
	btnLabelContainer: {
		display: 'flex',
		alignItems: 'center',
		width: 'fit-content',
		marginLeft: '8%',
		marginTop: '5%',
	},
}));

export default function AudioInput(props) {
	const {
		setInputData,
		setSampleRate,
		setFile,
		setInputSrc,
		isStreaming,
		setStreaming,
		setLanguage,
		apiKey,
	} = props;
	const [audioInput, setAudioInput] = useState(null);
	const classes = useStyles();
	function setNewAudio(inputDataFromChannel) {
		setInputData(inputDataFromChannel);
	}

	function onAudioStreamProcess(e) {
		const inputBuffer = e.inputBuffer;
		const inputDataFromChannel = inputBuffer.getChannelData(0);
		setNewAudio([...inputDataFromChannel]);
	}

	function initRecorder(stream) {
		const context = new window.AudioContext();
		setSampleRate(context.sampleRate);
		const audio = context.createMediaStreamSource(stream);
		const recorder = context.createScriptProcessor(BUFFER_SIZE, 1, 1);
		recorder.onaudioprocess = onAudioStreamProcess;
		if (context.state === 'suspended') {
			context.resume();
		}
		audio.connect(recorder);
		recorder.connect(context.destination);
		setAudioInput(recorder);
	}
	function setupTheVideo() {
		navigator.mediaDevices
			.getUserMedia({ audio: true })
			.then(function (stream) {
				initRecorder(stream);
			})
			.catch((err) => {
				console.log('Video is not working: ', err);
			});
	}
	function clickHandler() {
		if (navigator.mediaDevices.getUserMedia && !isStreaming) {
			setStreaming(true);
			setupTheVideo();
		} else {
			setStreaming(false);
			setInputData(null);
			audioInput.disconnect();
		}
	}
	function changeFile(evt) {
		if (!isStreaming) {
			setInputData(evt.target.files[0]);
			setStreaming(true);
		}
	}
	function disableStream(evt) {
		if (isStreaming) {
			setInputData(null);
			setLanguage('cleared');
			setStreaming(false);
			evt.preventDefault();
			console.log('Stopped the stream');
		}
	}
	const classList = classNames(classes.buttonRec, {
		[classes.notRec]: !isStreaming,
		[classes.rec]: isStreaming,
	});
	let speechLabel = !isStreaming ? 'Start speech input' : 'Stop speech input';
	let uploadSpeech = !isStreaming ? 'Start file input' : 'Stop file input';
	let button = (
		<div className={classes.btnLabelContainer}>
			<Button
				variant="contained"
				color="secondary"
				className={classList}
				onClick={clickHandler}
				startIcon={<KeyboardVoiceIcon />}
			>
				{speechLabel}
			</Button>
		</div>
	);
	let inputComponenet = (
		<input type="file" hidden onClick={disableStream} onChange={changeFile} />
	);
	let uploadFileBtn = (
		<div className={classes.btnLabelContainer}>
			<Button
				variant="contained"
				component="label"
				color="secondary"
				className={classes.uploadBtn}
				startIcon={<CloudUploadIcon />}
			>
				{uploadSpeech}
				{inputComponenet}
			</Button>
		</div>
	);
	return (
		<AudioSelectTabs
			setFile={setFile}
			button={button}
			setApiKey={props.setApiKey}
			uploadFileBtn={uploadFileBtn}
			language={props.language}
			apiKey={apiKey}
			setLanguage={props.setLanguage}
			setInputSrc={setInputSrc}
		/>
	);
}
